import React from 'react';
import WeCreate from "../components/wecreate";
import styled from 'styled-components';
import VideoBackground from "../components/videoBackground";
import VideoMockup from './videoMockup';
import AnimatedText from './animatedText';

const SuperTitle = styled.div`
    align-items: center;
    display: flex;
    height: 100vh;
    min-height: -webkit-fill-available;
justify-content: center;
width: 100vw;


h2{
    padding: 2rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    text-shadow: 0px 4px 12px rgb(0 0 0 / 50%);
}
`

const Wrapper = styled.div`

.wecreate,
.wecreate2,
.wecreate3{
    ${'' /* position:absolute; */}
    top:0;
    width: 100vw;
    min-height: var(--app-height);
    display:flex;
    flex-direction: column;

}

@media(max-width:1024px){
    h1{font-size:12vw};

    .wecreate,
.wecreate2,
.wecreate3{
    min-height:unset;
    padding: 100px 0;
}
}

.highlights{
    padding-top: 100px;
}
`

const Flux = (props) => {

    return <div>

        <Wrapper>
            <div className="kaleido">
                <VideoBackground video={"videoAbout1"} />
            </div>
            <div className="flux">
                <SuperTitle className="home">
                    <AnimatedText tag="h1-main" >About Us</AnimatedText>
                </SuperTitle>
                <div className="wecreate">
                    <WeCreate id={4} />
                </div>
                <div data-scroll data-scroll-direction="horizontal" data-scroll-speed="7">
                    <VideoMockup video={"videoAbout2"} />
                </div>
                <div className="wecreate2">
                    <WeCreate id={5} />
                </div>
            </div>
        </Wrapper>
    </div>
};

export default Flux;