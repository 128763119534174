import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import styled from 'styled-components';


const Wrapper = styled.div`
background-color:#181718;
padding: 3rem 0;

.swiper-container{
    overflow:visible;
}
.swiper-slide {
  ${'' /* width: 60%; */}
  background-color: #272727;
  padding:3rem;
}

.swiper-slide:nth-child(2n) {
    background-color: #bdbdbd;
}

@media(max-width:640px){
    .swiper-slide {
    padding: 1rem;
    box-sizing: border-box;
}}
`

// install Swiper modules
SwiperCore.use([Autoplay]);

const Press = ({ data }) => {
    return <Wrapper>
        <div data-scroll data-scroll-direction="horizontal" data-scroll-speed="3">
            <Swiper
                spaceBetween={20}
                slidesPerView={2}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 6,
                        spaceBetween: 60
                    },
                }}
                autoplay={{
                    delay: .500,
                }}
                loop={true}
                centeredSlides={true}
                speed={1000}
                scrollbar={{ draggable: true }}
                onSlideChange={(swiper) => { }}
                onProgress={(swiper) => { }}
                onReachEnd={(swiper) => { }}
            >

                {data.images.edges.map((image) => {
                    return <SwiperSlide><GatsbyImage image={image.node.childImageSharp.gatsbyImageData} alt={data.alt} height={"90px"} /></SwiperSlide>
                })}
            </Swiper>
        </div>
    </Wrapper>
};


export default function MyPress(props) {
    return (
        <StaticQuery
            query={graphql`{
      images: allFile(filter: {relativeDirectory: {eq: "press"}}) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
      }
      }
      }
      `}
            render={data => <Press data={data} {...props} />}
        />
    )
}