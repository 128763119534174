import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import Flux from "../components/fluxAbout";
import PressSlide from "../components/pressSlide";
import ArrowDown from "../components/arrowDown";
import Seo from "../components/seo"

const H1 = styled.h1`
    font-size: 7.5rem;
    white-space: nowrap;
    margin-left: -10vw;
    .outline{
        -webkit-text-stroke: 1px #fff;
    color:transparent;
    }

    @media(max-width:768px){
        margin-left: -30vw;
        font-size: 4.5rem;
    }
`




// markup
const AboutPage = () => {

    return (
        <>
            <div>
                <Layout>
                    <Seo title="About - Vertual" />
                    <Hover type="">
                        <main data-scroll data-scroll-id="update">
                            <ArrowDown />
                            <Flux />
                            <div className="highlights" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-3">
                                <H1>press <span className="outline">press</span> press <span className="outline">press</span> press <span className="outline">press</span></H1>
                            </div>
                            <div className="press">
                                <PressSlide />
                            </div>
                            <div className="footer">
                                <Footer page="highlights" />
                            </div>
                        </main>
                    </Hover>
                </Layout>
            </div>
        </>
    )
}

export default AboutPage




