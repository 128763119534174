import React from 'react';
import styled from 'styled-components'
import Container from './container';
import AnimatedText from './animatedText';

const WeCreateWrapper = styled.div`
display: flex;
align-items: center;
color: #fff;
flex-direction: column;
justify-content: center;

text-align: left;

`;
const WeCreate = (props) => {

    const sentences = {
        1: {
            paragraph1: ["VERTUAL is an innovative startup which focuses on creating new digital retail platforms that build the bridge to the Metaverse, a gateway between the real and the virtual reality.", "We design bespoke interactive and gaming platforms, regardless of the constraints and limitations of reality to create engaging and compelling shopping experiences that drive customer interaction and enhance conversion rate."]
        },
        2: {
            paragraph2: ["We merge physical and virtual capabilities to create memorable brand experiences, transforming the Internet browsing beyond flat screens and customer experience to a totally new frontier of storytelling — “the evolution from ‘storytelling’ to ‘story-living”.", "Our immersive digital worlds are accessible on the web with fully tailored experiences, entertainment, branding, gaming and purchasing opportunities all in one place."]
        },
        3: {
            paragraph3: ["We transform ordinary websites, apps and social medias into virtual boutiques, showrooms, pop-up stores and imaginary worlds.", "Our cloud-based platform integrates with an existing e-commerce to extend the product catalog into a memorable shopping experience, which can be easily embedded in a website and is fully optimized for desktop, mobile and VR headsets.",]
        },
        4: {
            paragraph4: ["Our team is composed by designers, software architects and engineers who believe in the future of metaverse as a new frontier of digital shopping.", "We are dreamers and are truly passionate about the quality of the experience we deliver, always striving to provide incredible quality, by using cutting edge technology."]
        },
        5: {
            paragraph5: ["We develop platforms designed to create virtual experiences, converging art, gaming and e-commerce, allowing Brands to amplify their digital universe while engaging with the new generation of consumers.", "We work with Brands that are looking to level-up their omnichannel experience and their online store, providing a complete digital design and production process, by incorporating new visual merchandising techniques that encourage audience interaction into a digital environment."]
        },
        6: {
            paragraph6: "We guide companies, providing a complete digital design and production process, by incorporating new visual merchandising techniques that encourage audience interaction into an e-commerce website, thus bringing new life into their digital strategy."
        },
        7: {
            paragraph7: "We create digital experiences that mixm together brand, content, architecture and digital with unlimited range of customizations, and disperse it across all channels."
        }
    }



    const sentencesArray = [];
    for (let key in sentences[props.id]) {
        sentencesArray.push({
            id: key,
            text: sentences[props.id][key]
        });
    }

    return <Container>
        <WeCreateWrapper>

            {sentencesArray.map(sentence => {
                return <>
                    <AnimatedText tag="paragraph" key={sentence.id}>{sentence.text[0]}</AnimatedText>
                    <AnimatedText tag="paragraph" key={sentence.id}>
                        {sentence.text[1]}
                    </AnimatedText>
                </>
            })}

        </WeCreateWrapper>
    </Container>
};

export default WeCreate;